import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { throttle } from 'lodash';

gsap.registerPlugin(ScrollTrigger);

// Disable some animation's scroll triggers < screen width 768px
document.addEventListener('DOMContentLoaded', () => {
  const media = window.matchMedia('screen and (max-width: 767px)');
  const triggers = ScrollTrigger.getAll();
  const mobileTriggers = ['count-trigger', 'wave-trigger', 'flag-01-trigger', 'flag-02-trigger'];
  ScrollTrigger.addEventListener('refreshInit', checkSTState);
  checkSTState();
  
  function checkSTState() {
    if (media.matches) {
      triggers.forEach(trigger => {
        console.log(trigger.vars.id);
        if(!mobileTriggers.includes(trigger.vars.id)) {
          trigger.disable();
        }
      })

    } else {
      triggers.forEach(trigger => {
        trigger.enable();
      })
    }
  }

  const buyBtn = document.getElementById('sticky-nav');
  const padding = 150;
  const sticky = (buyBtn.offsetTop + buyBtn.offsetHeight + padding);

  const runOnScroll = () => {
      if (window.scrollY >= sticky && window.innerWidth <= 480) {
          buyBtn.classList.add('button--visible');
          buyBtn.classList.remove('button--hidden');
      } else {
          buyBtn.classList.remove('button--visible');
          buyBtn.classList.add('button--hidden');
      }
  };
  window.addEventListener('scroll', throttle(runOnScroll, 250));
  window.addEventListener('resize', throttle(runOnScroll, 250));

})