import './plugins/gulp-watch';
import './plugins/util';
import './plugins/carousel';

import './animations/animations';
import './animations/expander';
import './animations/slidein';
import './animations/count';
import './animations/drawsvg';
import './animations/scrollBreakpoints';

