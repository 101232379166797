import { gsap } from 'gsap';
import $ from 'jquery';
import 'bootstrap';
import 'bootstrap/js/src/util';
import 'bootstrap/js/src/carousel';

document.addEventListener('DOMContentLoaded', () => {

  $('#carousel-example').carousel({
    pause: true,
    wrap: true,
    ride: false,
  })

  $('#carousel-example').on('slide.bs.carousel', function (e) {
    var $e = $(e.relatedTarget);
    var idx = $e.index();
    var itemsPerSlide = 5;
    var totalItems = $('.carousel-item').length;
  
    if (idx >= totalItems-(itemsPerSlide-1)) {
        var it = itemsPerSlide - (totalItems - idx);
        for (var i=0; i<it; i++) {
            // append slides to end
            if (e.direction=='left') {
                $('.carousel-item').eq(i).appendTo('.carousel-inner');
            }
            else {
                $('.carousel-item').eq(0).appendTo('.carousel-inner');
            }
        }
    }
  });

  let slides = gsap.utils.toArray('.carousel-item');
  let slideWidth = [];
  var modalOverlayNew = document.querySelector('.modalOverlayNew');
  var modal = document.querySelector('.messageWrapper');
  gsap.set([modalOverlayNew, modal], { autoAlpha: 0 });

  slides.forEach( (slide) => {
    slideWidth.push(slide.offsetWidth);

    let slideImg = slide.firstElementChild.cloneNode(true);
    slideImg.classList.add('message-img');
    let slideImgHTML = slideImg.outerHTML;

    let slideTxt = slide.lastElementChild.cloneNode(true);
    slideTxt.classList.add('message-txt');
    let slideTxtHTML = slideTxt.outerHTML;


    slide.addEventListener('click', () => {
      // Remove all active classes from slides
      let active = gsap.utils.toArray('.active-slide');
      active.forEach(function (active, index) {
        gsap.to(active, { width: slideWidth[index], scale: 1, duration: 0.35  })
        active.classList.remove('active-slide');
        active.style = '';
      })

      // Add active class to clicked slide
      slide.classList.add('active-slide');

      // Get slide position and tween from
      var newRect = getPosition(modal, slide);
      gsap.set(modal, {
        x: newRect.left,
        y: newRect.top,
        width: newRect.width,
        height: newRect.height,
      });

      let containerHeight = document.getElementById('top-content').offsetHeight;

      var tl = gsap.timeline();
      tl.to(modalOverlayNew, { autoAlpha: 0.75, duration: .35 });
      tl.fromTo(modal, 
        { 
          autoAlpha: 0,
          backgroundColor: 'transparent',
        },
        {
        autoAlpha: 1,
        x: 0,
        y: 0,
        backgroundColor: '#fff',
        height: containerHeight,
        width: '100vw',
        duration: .35,
      }, '<')
      tl.fromTo('.message', { autoAlpha: 0}, { duration: .4, autoAlpha: 1}, '>+=.15')
    
      document.querySelector('.message').innerHTML = slideImgHTML + slideTxtHTML;
    })
  })

  modalOverlayNew.addEventListener('click', function() {
    let activeSlide = document.getElementsByClassName('active-slide');
    if(activeSlide.length) {
      var newRect = getPosition(modal, activeSlide[0]);
      var tl = gsap.timeline();
      tl.to(modal, {
        autoAlpha: 0,
        x: newRect.left,
        y: newRect.top,
        height: newRect.height,
        width: newRect.width,
        duration: .5,
      });
      tl.to('.message', { autoAlpha: 0,duration: .5 }, '<');
      tl.to('.modalOverlayNew', { autoAlpha: 0, duration: .5 });
    }
    removeSlideStyles();
  })

  function removeSlideStyles() {
    let items = document.getElementsByClassName('carousel-item');
    for(let i=0; i<items.length; i++) {
      items[i].style = '';
    }
  }

  function getPosition(elem, target) {
    var targetRect = target.getBoundingClientRect();
    var elemRect = elem.getBoundingClientRect();
  
    gsap.set(elem, {
      x: 0,
      y: 0,
      width: targetRect.width,
      height: targetRect.height,
    });
    var newRect = elem.getBoundingClientRect();
    gsap.set(elem, { width: elemRect.width, height: elemRect.height });
    return {
      left: targetRect.left - newRect.left,
      top: targetRect.top - newRect.top,
      width: newRect.width,
      height: newRect.height,
    };
  }

})