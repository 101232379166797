import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {

  const leftSection = gsap.utils.toArray('.slide-in-left');
  const rightSection = gsap.utils.toArray('.slide-in-right');
  
  leftSection.forEach( (element) => {
    let img = element.querySelector('.slide-in-img');
    let txt = element.querySelector('.slide-in-txt');
    
    let tl = gsap.timeline()
      .fromTo(txt, {y:'60px', opacity: 0}, {y: '0px', opacity:1, duration:1})
      .fromTo(img, {x:'-100%'}, {x: '-12%', duration:1.25}, '<+=.1')
  
    ScrollTrigger.create({
      trigger:img,
      id: 'slide-left-trigger',
      start:'center-=300 center',
      animation:tl,
      // markers: true,
    })
  })

  rightSection.forEach( (element) => {
    let img = element.querySelector('.slide-in-img');
    let txt = element.querySelector('.slide-in-txt');

    let tl2 = gsap.timeline()
    .fromTo(txt, {y:'60px', opacity: 0}, {y: '0px', opacity:1, duration:1})
    .fromTo(img, {x:'200%'}, {x: '12%', duration:1.25}, '<+=.1')

    ScrollTrigger.create({
    trigger:img,
    id: 'slide-right-trigger',
    start:'center-=300 center',
    animation:tl2,
    // markers: true,
    })
  })

})