import { CountUp } from 'countup.js';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

document.addEventListener('DOMContentLoaded', () => {
  const countElements = gsap.utils.toArray('.count');

  countElements.forEach( (element) => {
    let countTo = element.dataset.count;
    let countDuration = element.dataset.duration;
    let countStart = element.dataset.from;

    ScrollTrigger.create({
      trigger:'#section-04',
      start:'center-=200 center',
      once: true,
      id: 'count-trigger',
      onUpdate: (self) => {
        const countUp = new CountUp(element, countTo, {
          startVal: countStart,
          duration: countDuration,
        })
        if (!countUp.error) {
          countUp.start();
          self.disable();
        } else {
          console.error(countUp.error);
        }
      
      },
      // markers: true,
    })
  })
})