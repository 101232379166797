import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(MotionPathPlugin);

document.addEventListener('DOMContentLoaded', () => {
  let arrows = gsap.utils.toArray('.arrow-group');
  let tl = gsap.timeline();

  tl.fromTo('#wave', {drawSVG: '0'}, {duration: 2.5, drawSVG: '100%'});
  tl.fromTo('#wave-stroke', {drawSVG: '0'}, {duration: 2.5, drawSVG: '100%'}, '<');

  arrows.forEach( (element) => {
    let head = element.querySelector('.arrow-head');
    let line = element.querySelector('.arrow-line');
    let text = element.querySelector('.text-group');
    
    tl.to(line, {opacity: 1, duration: .25}, '<');
    tl.to(head, {opacity: 1, duration: .25}, '<+=.2');
    tl.to(head, {
      duration: 2, 
      ease: 'back.out(1.7)',
      immediateRender: true,
      motionPath: {
        path: line,
        align: line,
        alignOrigin: [0.5, 0.5],
        start: 1,
        end: 0,
      },
    }, '<+=.25');
    tl.fromTo(line, {drawSVG: '100% 100%'}, {duration: 1, drawSVG: '0% 100%'},'<');
    tl.fromTo(text, {opacity: 0}, {duration: 1.5, delay: .5, opacity: 1},'<-=.5');
  })

  ScrollTrigger.create({
    trigger:'#arrows',
    id: 'wave-trigger',
    start:'center-=200 center',
    animation:tl,
    // markers: true,
  })

  ScrollTrigger.create({
    trigger:'#arrows',
    id: 'flag-01-trigger',
    start:'center-=200 center',
    once: true,
    onUpdate: (self) => {
      gsap.fromTo('#flag-01', {opacity: 0, display: 'none', x:'0'}, {duration: 2.5, opacity: 1, display: 'block', x:'-100%'});
      self.disable();
    },
    // markers: true,
  })

  ScrollTrigger.create({
    trigger:'#arrows',
    id: 'flag-02-trigger',
    start:'center-=200 center',
    once: true,
    onUpdate: (self) => {
      gsap.fromTo('#flag-02', {opacity: 0, display: 'none', x:'-100%'}, {duration: 2.5, opacity: 1, display: 'block', x:'0'});
      self.disable();
    },
    // markers: true,
  })

})