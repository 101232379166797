import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {
  // console.clear();

  gsap.defaults({ overwrite: 'auto' });

  const contentMarkers = gsap.utils.toArray('.contentMarker');

  let direction = 'down';

  // Set up our scroll trigger
  const ST = ScrollTrigger.create({
    trigger: '.content-container',
    id: 'pinned-trigger',
    start: 'top top',
    end: 'bottom bottom',
    onUpdate: (self) => {
      direction = self.direction === -1 ? 'up' : 'down'
      getCurrentSection()
    },
    pin: '.left-content',
    anticipatePin: 1,
    preventOverlaps: true,
    // markers: true,
  });

  // Set up our content behaviors
  contentMarkers.forEach(marker => {

    marker.next = marker.nextElementSibling
    if (marker.next) { marker.next.content = document.querySelector(`#${marker.next.dataset.markerContent}`) }

    marker.previous = marker.previousElementSibling
    if (marker.previous) { marker.previous.content = document.querySelector(`#${marker.previous.dataset.markerContent}`) }


    marker.content = document.querySelector(`#${marker.dataset.markerContent}`);

    gsap.set(marker.content, { transformOrigin: 'center' });

    marker.content.enter = function () {
      gsap.fromTo(marker.content, { autoAlpha: 0 }, { duration: 0.75, autoAlpha: 1});
    }

    marker.content.leave = function () {
      gsap.to(marker.content, { duration: 0.4, autoAlpha: 0 });
    }

  });

  // Handle the updated position
  let lastContent;
  let animContainer = document.getElementById('anim-container');

  function getCurrentSection() {

    let newContent;
    let nextContent;

    //const currScroll = scrollY;
    const currScroll = direction === 'down' ? scrollY - animContainer.offsetTop + 1 : scrollY - animContainer.offsetTop;

    // Find the current section
    contentMarkers.forEach(marker => {
      if (currScroll > marker.offsetTop - 100) {
        // console.log("Cur scroll " + currScroll);
        // console.log("marker.id " + marker.content.id);
        // console.log("marker.offsetTop " + marker.offsetTop);
        
        newContent = marker.content;

        nextContent = direction === 'down' ? marker.next ? marker.next.content : marker.content : marker.previous ? marker.previous.content : marker.content;

      }
    });

    // If the current section is different than that last, animate in
    if (newContent && (lastContent == null || !newContent.isSameNode(lastContent)) && !nextContent.isSameNode(lastContent)) {

      // Fade out last section
      if (lastContent) {
        lastContent.leave();
      }

      // Animate in new section
      newContent.enter();

      lastContent = newContent;

    }

  }

})